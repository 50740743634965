function scrollToTop() {
    const scrollToTopBtns = document.querySelectorAll('[data-scroll-to-top]');
    scrollToTopBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    });
}

scrollToTop();