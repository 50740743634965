import gsap from 'gsap';

class ColumnGraph extends HTMLDivElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.cols = [...this.querySelectorAll('.investment-box__graph__col__item')];
        this.values = this.gerPercentageValues();
        this.tl = this.createAnimationTl();
        this.tl.play();
    }

    gerPercentageValues() {
        const rawValues = this.cols.map((col) => Number(col.getAttribute('data-value')));
        const maxValue = Math.max(...rawValues);
        return rawValues.map((value) => Math.floor(value / maxValue * 100));
    }

    createAnimationTl() {
        const tl = gsap.timeline({paused: true});
        this.cols.forEach((col, idx) => {
            tl.to(col, {
                height: `${this.values[idx]}%`,
                duration: this.values[idx] / 100,
            }, '0');
        });
        return tl;
    }
}

customElements.define('column-graph', ColumnGraph, {extends: 'div'});