import { footerCurtain, footerVideo } from './FooterController.js';
import { ListingAnimations } from './ListingAnimations.js';
import { headerSubscribeButtonInit } from './HeaderController.js';
import { MobileMenu } from './MobileMenu.js';
import { initPotrfolioBackground } from './backgroundController.js';

export function initLayoutControllers() {
    footerCurtain();
    footerVideo();
    new ListingAnimations();
    headerSubscribeButtonInit();
    new MobileMenu();
    initPotrfolioBackground();
}
