import {FormController} from './formControllers/FormController.js';
import {InputController} from './formControllers/InputController.js';
import {ContactFormController} from './formControllers/ContactFormController.js';
import {FooterFormController} from './formControllers/FooterFormController.js';
import {ModalContactFormController} from './formControllers/ModalContactFormController.js';

const forms = document.querySelectorAll('.wpcf7-form');
forms.forEach((form) => {
    const formFields = form.querySelectorAll('label input, label textarea');
    formFields.forEach(input => new InputController(input));
    new FormController(form);
});

const mainContactForm = document.querySelector('.contact-block .wpcf7-form');
if (mainContactForm) {
    new ContactFormController(mainContactForm);
}

const footerForm = document.querySelector('.newsletter-sub__form');
if (footerForm) {
    new FooterFormController(footerForm);
}

const modalSubcribeForms = document.querySelectorAll('[data-tingle-modal="subscribe-modal"] form');
modalSubcribeForms.forEach((modalForm) => {
    new ModalContactFormController(modalForm);
});