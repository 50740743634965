import gsap from 'gsap';

export class FooterFormController {
    constructor(form) {
        this.form = form;
        this.formParent = form.closest('.newsletter-sub');
        if (!this.formParent) return;
        this.formTitle = {
            default: this.formParent.querySelector('.newsletter-sub__title .default'),
            dynamic: this.formParent.querySelector('.newsletter-sub__title .dynamic'),
            container: this.formParent.querySelector('.newsletter-sub__title'),
        };
        this.formSubtitle = {
            default: this.formParent.querySelector('.newsletter-sub__subtitle .default'),
            dynamic: this.formParent.querySelector('.newsletter-sub__subtitle .dynamic'),
            container: this.formParent.querySelector('.newsletter-sub__subtitle'),
        };
        this.formStatusIcon = this.formParent.querySelector('.form-status-icon');
        this.attachListeners();
        this.tl = this.createTl();
        
    }
    
    createTl() {
        const tl = gsap.timeline({paused: true});
        tl.to(this.form, {height: 0, autoAlpha: 0, duration: 1}, 0);
        tl.fromTo(this.formTitle.container, {marginTop: 0}, {marginTop: () => this.formStatusIcon.getBoundingClientRect().height + 16, duration: 1}, 0);
        tl.fromTo(this.formStatusIcon, {autoAlpha: 0, y: -100}, {autoAlpha: 1, y: 0, duration: 1}, 0);
        tl.addLabel('textReplacements', 1);
        tl.fromTo(this.formTitle.default, {y: 0}, {y: 100, duration: 1}, 'textReplacements');
        tl.fromTo(this.formTitle.dynamic, {y: -110}, {y: 0, duration: 1}, 'textReplacements');
        tl.fromTo(this.formSubtitle.default, {y: 0}, {y: 100, duration: 1}, 'textReplacements');
        tl.fromTo(this.formSubtitle.dynamic, {y: -110}, {y: 0, duration: 1}, 'textReplacements');
        tl.to(this.formSubtitle.container, {height: () => {
            return this.formSubtitle.dynamic.getBoundingClientRect().height;
        }, duration: 1}, 'textReplacements');
        return tl;
    }

    attachListeners() {
        this.form.addEventListener('submit', this.submitHandler.bind(this));
        this.form.addEventListener('wpcf7submit', this.sentHandler.bind(this));
    }



    submitHandler() {
        this.formParent.classList.add('form-status--loading');
        this.formSubtitle.dynamic.textContent = '';
        this.formStatusIcon.classList.remove('form-status-icon--fail');
        this.formStatusIcon.classList.remove('form-status-icon--success');
    }

    sentHandler(event) {
        this.formSubtitle.dynamic.textContent = event.detail.apiResponse.message;
        if (event.detail.status !== 'mail_sent') {
            this.formTitle.dynamic.textContent = this.formTitle.container.getAttribute('data-fail-msg');
            this.formStatusIcon.querySelector('svg').setAttribute('xlink:href', this.formStatusIcon.getAttribute('data-icon-fail'));
            this.formStatusIcon.classList.add('form-status-icon--fail');
            this.formParent.classList.remove('form-status--loading');
            this.formParent.classList.remove('form-status--success');
            this.formParent.classList.add('form-status--fail');
            if (event.detail.status !== 'validation_failed') {
                this.tl.play();
            }
        } else {
            this.formTitle.dynamic.textContent = this.formTitle.container.getAttribute('data-success-msg');
            this.formStatusIcon.querySelector('svg').setAttribute('xlink:href', this.formStatusIcon.getAttribute('data-icon-success'));
            this.formStatusIcon.classList.add('form-status-icon--success');
            this.formParent.classList.remove('form-status--loading');
            this.formParent.classList.add('form-status--success');
            this.formParent.classList.remove('form-status--fail');
            this.tl.play();
        }
    }
}