import gsap from 'gsap';
import axios from 'axios';
import {Article} from './utils/Article.js';
import {Dropdown} from './utils/Dropdown.js';
import {getCssVar} from './utils/helpers.js';

class NewsListing extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.listing = this.querySelector('.news-listing');
        this.articleTemaplte = this.querySelector('#post-template');
        this.apiData = {
            api: this.getAttribute('data-api'),
            perPage: Number(this.getAttribute('data-per-page'))
        };
        this.mapFilterData();
        this.mapPaginationData();
        this.dropdownControllers = this.filterItems.map((item) => new Dropdown(item));

        this.attachListeners();
        if (document.readyState !== 'complete') {
            addEventListener('DOMContentLoaded', () => this.iniAnimation());
        } else {
            this.iniAnimation();
        }
    }

    mapPaginationData() {
        this.loadMoreBtn = this.querySelector('.nav-previous');
        this.currentPage = 1;
    }

    mapFilterData() {
        this.activeFilters = {};
        this.allFilters = {};
        this.filtersContainer = this.querySelector('.filters');
        this.filterItems = [...this.filtersContainer.querySelectorAll('[data-taxonomy-name]')];
        this.filterItems.forEach((filter) => {
            const taxonomyName = filter.getAttribute('data-taxonomy-name');
            this.allFilters[taxonomyName] = [...filter.querySelectorAll('[data-term-id]')];
            this.activeFilters[taxonomyName] = [];
        });
    }

    iniAnimation() {
        if ([...this.listing.children].length) {
            this.parallelScrollAnimation([...this.listing.children]);
        }
    }
    
    attachListeners() {
        for (const [taxonomyName, terms] of Object.entries(this.allFilters)) {
            terms.forEach((term) => {
                term.addEventListener('click', () => {
                    this.updateFilters(taxonomyName, term);
                    this.replaceArticles();
                });
            });
        }
        this.loadMoreBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.currentPage++;
            this.moreArticles();
        });
    }
    
    
    updateFilters(taxonomyName, term) {
        const alreadyActiveIdx = this.activeFilters[taxonomyName].findIndex((activeTerm) => activeTerm === term);
        if (alreadyActiveIdx !== -1) {
            this.activeFilters[taxonomyName].splice(alreadyActiveIdx, 1);
            this.updateButtonsState(false, term);
        } else {
            this.activeFilters[taxonomyName].push(term);
            this.updateButtonsState(true, term);
        }
        this.updateFiltersCounter();
    }

    updateFiltersCounter() {
        for (const [taxonomyName, terms] of Object.entries(this.activeFilters)) {
            const filterItemEl = this.filterItems.find((item) => item.getAttribute('data-taxonomy-name') === taxonomyName);
            const activeFiltersCount = terms.length;
            const matchedController = this.dropdownControllers.find((controller) => controller.dropdownParent === filterItemEl);
            matchedController.updateFiltersCounter(activeFiltersCount);
        }
    }
    
    getAllFilterButtons() {
        const buttons = [];
        for (const [taxonomyName, terms] of Object.entries(this.allFilters)) {
            terms.forEach((term) => {
                buttons.push(term);
            });
        }
        return buttons;
    }
    
    handlePagination(totalPages) {
        if (this.currentPage === Number(totalPages)) {
            this.loadMoreBtn.style.display = 'none';
        } else {
            this.loadMoreBtn.style.display = '';
        }
    }
    
    async replaceArticles() {
        this.currentPage = 1;
        const {articles, totalPages} = await this.getArticles();
        this.listing.textContent = '';
        articles.forEach((article) => {
            this.listing.appendChild(article);
        });
        this.iniAnimation();
        this.handlePagination(totalPages);
    }
    
    async moreArticles() {
        const {articles, totalPages} = await this.getArticles();
        articles.forEach((article) => {
            this.listing.appendChild(article);
        });
        this.iniAnimation();
        this.handlePagination(totalPages);
    }
    
    setLoaders(isDisabled) {
        if (isDisabled) {
            this.loadMoreBtn.classList.add('disabled');
            this.getAllFilterButtons().forEach((button) => {
                button.classList.add('disabled');
            });
        } else {
            this.loadMoreBtn.classList.remove('disabled');
            this.getAllFilterButtons().forEach((button) => {
                button.classList.remove('disabled');
            });
        }
    }
    
    async getArticles() {
        const apiUrl = new URL(`${this.apiData.api}wp/v2/posts`);
        for (const [taxonomyName, terms] of Object.entries(this.activeFilters)) {
            if (terms.length) {
                apiUrl.searchParams.append(taxonomyName.toLowerCase(), terms.map((term) => term.getAttribute('data-term-id')));
            }
            apiUrl.searchParams.append('per_page', this.apiData.perPage);
            apiUrl.searchParams.append('_embed', '');
            apiUrl.searchParams.append('page', this.currentPage);
        }
        this.setLoaders(true);
        const {data, headers} = await axios.get(apiUrl.toString());
        this.setLoaders(false);
        return {
            articles: data.map((article) => new Article(article, this.articleTemaplte)),
            totalPages: headers['x-wp-totalpages']
        };
    }
    
    updateButtonsState(isActive, button) {
        isActive ? button.classList.add('active') : button.classList.remove('active');
    }

    parallelScrollAnimation(els) {
        let mm = gsap.matchMedia();
        const mobileWidth = parseFloat(getCssVar('--breakpoint-mobile')) + 1;
        els.forEach((el, idx) => {
            const realIdx = idx + 1;
            mm.add(`(min-width: ${mobileWidth}px)`, () => {
                gsap.fromTo(el, {
                    yPercent: 20,
                }, {
                    yPercent: (realIdx % 2  === 0) ? -50 : -20,
                    scrollTrigger: {
                        trigger: el,
                        scrub: true
                    }
                });
            });
        });
    }

    disconnectedCallback() {
    }
}

customElements.define('news-listing', NewsListing);