import Swiper from 'swiper';
import { Navigation, EffectFade } from 'swiper/modules';
import gsap from 'gsap';
import { getCssVar } from './utils/helpers.js';

class TeamSlider extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.mainSliderContainer = this.querySelector('.team-slider');
        this.thumbsSliderContainer = this.querySelector('.team-photos-slider');
        this.navigationCurrentSlide = this.mainSliderContainer.querySelector(
            '.swiper-nav__range--current',
        );
        this.inAnimationTimeoutId;
        this.thumbsSwiper = undefined;
        this.checkSlidesCount();
        this.initSwiper();
        this.watchResize();
    }

    disconnectedCallback() {
        clearTimeout(this.inAnimationTimeoutId);
    //this.mainSwiper.destroy();
    //this.thumbsSwiper.destroy();
    }

    checkSlidesCount() {
        const swiperWrapper =
      this.thumbsSliderContainer.querySelector('.swiper-wrapper');
        const els = [
            ...this.thumbsSliderContainer.querySelectorAll('.swiper-slide'),
        ];
        while (
            this.thumbsSliderContainer.querySelectorAll('.swiper-slide').length < 12
        ) {
            els.forEach((el) => {
                swiperWrapper.appendChild(el.cloneNode(true));
            });
        }
    }

    teamMemberIn(activeSlide) {
        const activeSlideRect = activeSlide.getBoundingClientRect();
        gsap.to(activeSlide, {
            y:
        this.mainSliderContainer.getBoundingClientRect().y -
        activeSlideRect.y +
        activeSlideRect.height * 0.1,
            scale: 1.2,
        });
    }

    teamMemberOut(prevSlide) {
        gsap.to(prevSlide, {
            y: 0,
            scale: 1,
        });
    }

    checkIsMobile() {
        return window.matchMedia(`(max-width: ${getCssVar('--breakpoint-mobile')})`)
            .matches;
    }

    watchResize() {
        let breakPoint = window.matchMedia(
            `(max-width: ${getCssVar('--breakpoint-mobile')})`,
        );
        breakPoint.addEventListener('change', (e) => {
            const activeSlide =
        this.thumbsSwiper.slides[this.thumbsSwiper.activeIndex];
            if (!activeSlide) return;
            if (e.matches) {
                if (activeSlide.getAttribute('style')) {
                    activeSlide.style = '';
                }
            } else {
                if (!activeSlide.getAttribute('style')) {
                    this.teamMemberIn(activeSlide);
                }
            }
        });
    }

    initSwiper() {
        this.thumbsSwiper = new Swiper(this.thumbsSliderContainer, {
            loop: true,
            initialSlide: 0, // Start from the second item as first visible
            slidesPerView: 'auto', // Number of visible slides
            spaceBetween: 0, // Space between slides
            speed: 1000,
            allowTouchMove: false,
            on: {
                init: (swiper) => {
                    swiper.slidePrev(0);
                    swiper.slideNext(0);
                    this.inAnimationTimeoutId = setTimeout(() => {
                        const activeSlide = swiper.slides[swiper.activeIndex];
                        if (this.checkIsMobile()) return;
                        requestAnimationFrame(() => {
                            this.teamMemberIn(activeSlide);
                        });
                    }, 1000);
                    swiper.on('slideChangeTransitionEnd', (swiper) => {
                        const activeSlide = swiper.slides[swiper.activeIndex];
                        if (this.checkIsMobile()) return;
                        this.teamMemberIn(activeSlide);
                    });
                    swiper.on('slideChangeTransitionStart', (swiper) => {
                        const prevSlide = swiper.slides[swiper.previousIndex];
                        if (this.checkIsMobile()) return;
                        this.teamMemberOut(prevSlide);
                    });
                },
            },
        });
        this.mainSwiper = new Swiper(this.mainSliderContainer, {
            modules: [EffectFade, Navigation],
            initialSlide: 0,
            speed: 1000,
            loop: true,
            effect: 'fade',
            autoHeight: true,
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                prevEl: this.querySelector('.swiper-nav-item--prev'),
                nextEl: this.querySelector('.swiper-nav-item--next'),
            },
            on: {
                slideChange: (swiper) => {
                    this.navigationCurrentSlide.textContent = swiper.realIndex + 1;
                },
                navigationNext: () => {
                    this.thumbsSwiper.slideNext();
                },
                navigationPrev: () => {
                    this.thumbsSwiper.slidePrev();
                },
            },
        });
    }
}

customElements.define('team-slider', TeamSlider);
