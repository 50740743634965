export class Article {
    constructor(articleData, articleTemplate) {
        this.article = document.importNode(articleTemplate.content, true);
        this.articleData = articleData;
        this.defineMap();
        this.createAricle();
        return this.article;
    }
    
    defineMap() {
        this.articleMap = {
            title: this.article.querySelector('.article-slide__title'),
            desc: this.article.querySelector('.article-slide__desc'),
            categories: this.article.querySelector('.article-slide__meta__categories'),
            date: this.article.querySelector('.article-slide__meta__date'),
            image: this.article.querySelector('.article-slide__image a'),
        };
    }
    
    createAricle() {
        this.articleMap.title.textContent = this.articleData.title.rendered;
        this.articleMap.desc.textContent = this.articleData.excerpt.rendered;
        this.articleMap.image.setAttribute('href', this.articleData.link);
        this.articleMap.categories.textContent = '';
        this.articleMap.image.textContent = '';
        try {
            this.attachCategories();
        } catch (e) {
            console.error('Failed to generate article categories', e);
        }
        try {
            this.articleMap.image.appendChild(this.createImage(this.articleData._embedded['wp:featuredmedia'][0]));
        } catch (e) {
            console.error('Failed to generate article image', e);
        }
        
        const options = { month: 'long', day: 'numeric',  year: 'numeric', };
        this.articleMap.date.textContent = new Date(this.articleData.date).toLocaleDateString('en-US', options);
    }

    createImage(imageData) {
        const img = document.createElement('img');
        img.src = imageData.media_details.sizes.full.source_url;
        img.alt = imageData.alt_text;
        return img;
    }
    
    attachCategories() {
        const terms = this.articleData._embedded['wp:term'].flat();
        const categories = terms.filter((term) => term.taxonomy === 'category');
        this.generateCategories(categories).forEach((category) => {
            this.articleMap.categories.appendChild(category);
        });
    }

    generateCategories(categories) {
        return categories.map((category) => {
            const span = document.createElement('span');
            span.classList.add('tag');
            span.textContent = category.name;
            return span;
        });
    }
}