export function vertexShader() {
    return `
    attribute float scale;

    #define PI 3.14159265359

    uniform float u_time;
    uniform float u_pointsize;
    uniform vec3 uMouse;
    attribute vec3 color;
    varying vec3 vColor;

    void main() {
    vColor = color;
    vec3 pos = position;
    vec3 transformed = pos;
    vec3 seg = pos - uMouse;
    vec3 dir = seg;
    float dist = length(seg);
    float force = clamp(1. / (dist * dist), 0., .5);
    transformed += dir * force;
    vec4 mvm = modelViewMatrix * vec4(transformed, 1.0);

    gl_PointSize = u_pointsize * scale;
    bool isPerspective = ( projectionMatrix[ 2 ][ 3 ] == - 1.0 );

	if ( isPerspective ) gl_PointSize *= ( 20.0 / - mvm.z ) + force * 3.;

    gl_Position = projectionMatrix * mvm;

    }
    `;
}

export function fragmentShader() {
    return `
    varying vec3 vColor;

    void main() {
    if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
    gl_FragColor = vec4( vColor, 1.0 );
    }
    `;
}
