import './styles/app.sass';
import { default as debounce } from 'lodash.debounce';
import { resizeHandler } from './js/ResizeHandler.js';
import { initLayoutControllers } from './js/parts/layoutControllers/index.js';
import './js/parts/FormParts.js';
import './js/parts/Micros.js';
import './js/components/TextSlider.js';
import './js/components/CurrentTime.js';
import './js/components/Portfolio.js';
import './js/components/CursorTile.js';
import './js/components/CounterIncrementor.js';
import './js/components/ColumnGraph.js';
import './js/components/NewsSlider.js';
import './js/components/TeamSlider.js';
import './js/components/NewsListing.js';
import './js/parts/sphere/AbstractAnimation6.js';

function domLoadedHandler() {
    resizeHandler();
    initLayoutControllers();
}

document.addEventListener('DOMContentLoaded', domLoadedHandler);
window.addEventListener('resize', debounce(resizeHandler, 100));
