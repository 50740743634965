import tingle from 'tingle.js';

export class NotificationModalController {
    constructor(modalType, modalText) {
        this.modalText = modalText;
        this.modalContent = modalType === 'success' ? document.querySelector('[data-tingle-modal="success-modal"]') : document.querySelector('[data-tingle-modal="fail-modal"]');
        this.modalContent = this.modalContent.cloneNode(true);
        this.modal = this.createModal();
    }
    
    createModal() {
        const messageHolder = this.modalContent.querySelector('.modal-wrapper__content .content');
        messageHolder.textContent = this.modalText;
        const closers = this.modalContent.querySelectorAll('.modal__closer, .modal-wrapper__actions button');
        const modal = new tingle.modal({
            closeMethods: ['overlay', 'escape'],
            closeLabel: 'Close',
            cssClass: ['success-modal'],
        });
        closers.forEach((closer) => {
            closer.addEventListener('click', () => {
                this.destroyModal();
            });
        });
        modal.setContent(this.modalContent);
        return modal;
    }
    openModal() {
        this.modal.open();
    }

    closeModal() {
        this.modal.close();
    }

    destroyModal() {
        this.modal.destroy();
    }
}