let screenWidth = null;
let vh = null;
let headerHeight = null;

export function resizeHandler() {
    if (window.innerWidth !== screenWidth) {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        headerHeight = Math.floor(document.querySelector('header').getBoundingClientRect().height);
        document.documentElement.style.setProperty('--headerHeight', `${headerHeight}px`);
    }
    screenWidth = window.innerWidth;
}