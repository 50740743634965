import {NotificationModalController} from '../modalsControllers/NotificationModalController.js';

export class ModalContactFormController {
    constructor(form) {
        this.form = form;
        this.currentModal = this.form.closest('[data-tingle-modal]');
        this.currentModalCloser = this.currentModal.querySelector('.modal__closer');
        this.attachListeners();
    }

    attachListeners() {
        this.form.addEventListener('wpcf7submit', this.sentSuccessfullyHandler.bind(this));
        this.form.addEventListener('wpcf7mailfailed', this.sentFailHandler.bind(this));
    }

    sentSuccessfullyHandler(event) {
        if (event.detail.status === 'mail_sent') {
            const message = event.detail.apiResponse.message;
            this.currentModalCloser.click();
            const modal = new NotificationModalController('success', message);
            modal.openModal();
        }
    }
    
    sentFailHandler(event) {
        const message = event.detail.apiResponse.message;
        const modal = new NotificationModalController('fail', message);
        modal.openModal();
    }
}