export class FormController {
    constructor(form) {
        this.form = form;
        this.attachListeners();
    }

    attachListeners() {
        this.form.addEventListener('submit', this.submitHandler.bind(this));
        this.form.addEventListener('wpcf7submit', this.sentHandler.bind(this));
    }

    submitHandler() {
        this.form.classList.add('form-status--loading');
    }

    sentHandler(event) {
        if (event.detail.status !== 'mail_sent') {
            this.form.classList.remove('form-status--loading');
            this.form.classList.remove('form-status--success');
            this.form.classList.add('form-status--fail');
        } else {
            this.form.classList.remove('form-status--loading');
            this.form.classList.add('form-status--success');
            this.form.classList.remove('form-status--fail');
        }

    }
}