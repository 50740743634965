import gsap from 'gsap';
import {NotificationModalController} from '../modalsControllers/NotificationModalController.js';
import {getCssVar} from '../../components/utils/helpers.js';

export class ContactFormController {
    constructor(form) {
        this.form = form;
        this.formStatusBlock = form.closest('section').querySelector('.form-status');
        this.attachListeners();
    }

    attachListeners() {
        this.form.addEventListener('submit', this.submitHandler.bind(this));
        this.form.addEventListener('wpcf7submit', this.sentHandler.bind(this));
        this.form.addEventListener('wpcf7mailfailed', this.sentFailHandler.bind(this));
    }



    submitHandler() {
        this.formStatusBlock.classList.add('form-status--loading');
        const messageBox = this.formStatusBlock.querySelector('.form-status__bottom');
        messageBox.innerHTML = '';
    }

    sentHandler(event) {
        if (!this.formStatusBlock) return;
        const messageBox = this.formStatusBlock.querySelector('.form-status__bottom');
        const message = event.detail.apiResponse.message;
        const messageEl = this.createMessage(event.detail.apiResponse.message);
        messageBox.appendChild(messageEl);
        if (event.detail.status !== 'mail_sent') {
            this.formStatusBlock.classList.remove('form-status--loading');
            this.formStatusBlock.classList.remove('form-status--success');
            this.formStatusBlock.classList.add('form-status--fail');
            if (this.checkIsTablet()) {
                const modal = new NotificationModalController('fail', message);
                modal.openModal();
            }
        } else {
            this.formStatusBlock.classList.remove('form-status--loading');
            this.formStatusBlock.classList.add('form-status--success');
            this.formStatusBlock.classList.remove('form-status--fail');
            if (this.checkIsTablet()) {
                const modal = new NotificationModalController('success', message);
                modal.openModal();
            }
        }
        gsap.fromTo(messageEl, {yPercent: 100, autoAlpha: 0}, {yPercent: 0, autoAlpha: 1});
    }

    sentFailHandler(event) {
        if (this.checkIsTablet()) {
            const message = event.detail.apiResponse.message;
            const modal = new NotificationModalController('fail', message);
            modal.openModal();
        }
    }

    checkIsTablet() {
        return window.matchMedia(`(max-width: ${getCssVar('--breakpoint-tablet')})`).matches;
    }

    createMessage(message) {
        const el = document.createElement('p');
        el.textContent = message;
        return el;
    }
}