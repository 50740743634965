export class InputController {
    constructor(input) {
        this.input = input;
        this.attachListeners();
    }
    
    attachListeners() {
        this.input.addEventListener('input', this.notEmptyHandler);
    }
    
    notEmptyHandler(event) {
        const field = event.currentTarget;
        const label = field.closest('label');
        if (!label) return;
        if (field.value.length) {
            label.classList.add('not-empty');
        } else {
            label.classList.remove('not-empty');
        }
    }
}