class CursorTile extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.customCursor = null;
        this.cursorText = this.getAttribute('data-cursor-text');
        this.showArrrow = this.getAttribute('data-cursor-arrow');
        this.attachListeners();
        this.isCursorActive = false;
    }

    createCursor(cursor, mouseX, mouseY) {
        const svg = document.createElement('span');
        svg.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">\n' +
            '<path d="M8.7124 21.75C8.7124 23.025 9.6874 24 10.9624 24C12.2374 24 13.2124 23.025 13.2124 21.75C13.2124 21.3 13.0624 20.925 12.8374 20.55C12.2374 19.575 11.7874 18.525 11.7124 17.4C11.7124 17.025 11.7124 16.725 11.7124 16.425L11.7124 5.025C11.7124 4.125 12.8374 3.675 13.4374 4.275L18.1624 9C18.6874 9.525 19.1374 10.5 19.5124 10.875C19.7374 11.1 20.0374 11.175 20.3374 11.175C20.8624 11.175 21.4624 10.8 21.4624 10.05C21.4624 9.75 21.3874 9.45 21.1624 9.225C20.7874 8.85 19.7374 8.325 19.2874 7.875L11.4874 0.225C11.3374 0.0750001 11.1124 1.23423e-07 10.9624 1.25211e-07C10.7374 1.27894e-07 10.5874 0.0750001 10.4374 0.225L7.4374 3.225C3.6124 7.05 2.2624 7.35 1.6624 7.65C1.1374 7.875 0.537401 8.325 0.537401 9.375C0.537401 10.425 1.3624 11.25 2.4124 11.25C3.2374 11.25 3.9124 10.65 4.1374 10.05C4.3624 9.375 5.5624 7.275 8.5624 4.275C9.1624 3.675 10.2874 4.125 10.2874 5.025L10.2874 16.5C10.2874 16.8 10.2874 17.1 10.2874 17.475C10.2124 18.6 9.7624 19.65 9.1624 20.625C8.8624 20.925 8.7124 21.3 8.7124 21.75Z" fill="currentColor"/>\n' +
            '</svg>';
        const text = document.createElement('span');
        text.textContent = this.cursorText;
        cursor = document.createElement('span');
        cursor.classList.add('tile-cursor');
        svg.classList.add('tile-cursor__icon');
        this.showArrrow ? cursor.appendChild(svg) : false;
        this.cursorText? cursor.appendChild(text) : false;
        cursor.style.left = `${mouseX}px`;
        cursor.style.top = `${mouseY}px`;
        document.body.appendChild(cursor);
        requestAnimationFrame(() => {
            cursor.classList.add('active');
        });
        return cursor;
    }

    enableCursor(mouseX, mouseY) {
        let cursor = document.querySelector('.tile-cursor');
        if (!cursor) {
            cursor = this.createCursor(cursor, mouseX, mouseY);
        }
        cursor.style.display = '';
        this.customCursor = cursor;

    }

    disableCursor() {
        if (this.customCursor) {
            this.customCursor.style.display = 'none';
        }
        this.customCursor = null;
    }

    removeCursor() {
        this.customCursor?.remove();
    }

    attachListeners() {
        this.addEventListener('mouseenter', () => {
            this.isCursorActive = true;
            document.addEventListener('mousemove', this.updateCursor.bind(this));
        });
        this.addEventListener('mouseleave', (e) => {
            const leftTo = e.relatedTarget;
            this.isCursorActive = false;
            document.removeEventListener('mousemove', this.updateCursor.bind(this));
            if (leftTo && (leftTo.tagName !== 'cursor-tile' && !leftTo.closest('cursor-tile'))) {
                this.removeCursor();
            }
            this.disableCursor();
        });
    }

    updateCursor(event) {
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        if (!this.customCursor && this.isCursorActive) {
            this.enableCursor(mouseX, mouseY);
        }
        requestAnimationFrame(() => {
            if (!this.customCursor) return;
            this.customCursor.style.left = `${mouseX}px`;
            this.customCursor.style.top = `${mouseY}px`;
        });
    }


}

customElements.define('cursor-tile', CursorTile);