class CounterIncrementor extends HTMLSpanElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.targetValue = Number(this.getAttribute('data-target'));
        this.duration = Number(this.getAttribute('data-duration'));
        this.placeholderEl = this.createPlaceholder();
        this.targetEl = this.createValueHolder();
        this.animateValue(0, this.targetValue, this.duration);
    }

    createValueHolder() {
        const value = document.createElement('span');
        value.style.position = 'absolute';
        value.style.left = '0px';
        value.style.bottom = '0px';
        this.placeholderEl.appendChild(value);
        return value;
    }

    createPlaceholder() {
        const placeholder = document.createElement('span');
        placeholder.style.position = 'relative';
        const placeholderValue = document.createElement('span');
        placeholderValue.style.visibility = 'hidden';
        placeholderValue.textContent = this.targetValue.toString();
        placeholder.appendChild(placeholderValue);
        this.appendChild(placeholder);
        return placeholder;

    }

    animateValue(start, target, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            let num = Math.floor(progress * (target - start) + start).toString();
            while (num.length < target.toString().length) {
                num = '0' + num;
            }
            this.targetEl.textContent = num;
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }
}

customElements.define('count-incrementor', CounterIncrementor, {extends: 'span'});