import { gsap } from 'gsap';
import { Dropdown } from './utils/Dropdown.js';

class Portfolio extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.selectedRegion = 'All';
        this.allTiles = [];
        this.visibleTiles = [];
        this.findElements();
        this.attachListeners();
        this.createCtaAnimations();
        this.initDropdown();
    }

    initDropdown() {
        this.dropdownController = new Dropdown(
            this.querySelector('.portfolio__filters__dropdown'),
        );
    }

    findElements() {
        this.ctaButton = this.querySelector('.portfolio__listing__cta');
        this.switchers = [...this.querySelectorAll('[data-region]')];
        this.allTiles = [...this.querySelectorAll('.portfolio-tile__wrapper')];
        this.visibleTiles = [...this.allTiles];
        this.listingBlock = this.querySelector('.portfolio__listing');
    }

    attachListeners() {
        this.switchers.forEach((switcher) => {
            switcher.addEventListener('click', this.filterTiles.bind(this));
        });
    }

    async filterTiles(event) {
        this.switchers.forEach((switcher) => switcher.classList.remove('active'));
        const activeButton = event.currentTarget;
        activeButton.classList.add('active');
        this.selectedRegion = activeButton.getAttribute('data-region');
        const newTiles = this.allTiles.filter((tile) => {
            if (this.selectedRegion === 'All') return true;
            const regions = JSON.parse(tile.getAttribute('data-regions'));
            return regions.includes(this.selectedRegion);
        });
        this.toggleInteractions();
        await this.replaceTiles(newTiles);
        this.visibleTiles = newTiles;
        this.toggleInteractions();
    }

    toggleInteractions() {
        this.switchers.forEach((switcher) => switcher.classList.toggle('disabled'));
    }

    async replaceTiles(newTiles) {
        await new Promise((resolve) => {
            const tl = gsap.timeline({
                onComplete: resolve(),
            });
            tl.add(this.ctaButtonAnimationOut);
            tl.set(this.listingBlock, {
                height: () => this.listingBlock.getBoundingClientRect().height + 2,
            });
            tl.to(this.visibleTiles.slice(0, 12), {
                duration: 0.3,
                yPercent: 40,
                stagger: 0.05,
                autoAlpha: 0,
            });
            tl.to(
                this.visibleTiles.slice(12, this.visibleTiles.length),
                {
                    duration: 0.3,
                    yPercent: 40,
                    autoAlpha: 0,
                },
                '-=.25',
            );
            tl.set(this.visibleTiles, { display: 'none' });
            tl.set(newTiles, { display: '' });
            tl.to(this.listingBlock, { height: 'auto' });
            tl.to(
                newTiles.slice(0, 12),
                {
                    stagger: 0.1,
                    yPercent: 0,
                    autoAlpha: 1,
                },
                '<',
            );
            tl.to(
                newTiles.slice(12, newTiles.length),
                {
                    yPercent: 0,
                    autoAlpha: 1,
                },
                '-=.25',
            );
            tl.add(this.ctaButtonAnimationIn);
            //tl.totalDuration(2);
        });
    }

    createCtaAnimations() {
        this.ctaButtonAnimationOut = gsap.to(this.ctaButton, {
            scale: 0,
            duration: 0.3,
        });
        this.ctaButtonAnimationIn = gsap.to(this.ctaButton, {
            scale: 1,
            duration: 0.3,
        });
    }
}

customElements.define('portfolio-block', Portfolio);
