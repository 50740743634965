import {SubscribeModalController} from '../modalsControllers/SubscribeModalController.js';



export function headerSubscribeButtonInit() {
    const header = document.querySelector('.header-inner');
    const button = header.querySelector('.contact-button');
    const modal = new SubscribeModalController();
    button.addEventListener('click', () => {
        modal.open();
    });
}