class CurrentTime extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.timerId = null;
        this.initTimer();
    }

    initTimer() {
        this.timerId = setInterval(this.updateCurrentTime.bind(this), 1000);
    }

    updateCurrentTime() {
        this.textContent = new Date().toLocaleTimeString();
    }

    disconnectedCallback() {
        clearInterval(this.timerId);
        this.timerId = null;
    }
}

customElements.define('current-time', CurrentTime);