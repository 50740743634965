import { default as throttle } from 'lodash.throttle';
import { default as debounce } from 'lodash.debounce';
import { setCssVar } from '../../components/utils/helpers.js';

export function footerCurtain() {
    const footer = document.querySelector('footer.site-footer');
    const header = document.querySelector('header');

    if (footer) {
        const updateMargin = () => {
            setCssVar(
                '--curtain-margin',
                footer.getBoundingClientRect().height + 'px',
            );
        };
        const updateVisibility = () => {
            const headerPos = header.getBoundingClientRect();
            if (Math.abs(headerPos.top) > headerPos.height + 10) {
                footer.style.visibility = 'visible';
            } else {
                footer.style.visibility = '';
            }
        };
        updateMargin();
        document.addEventListener('scroll', throttle(updateMargin, 1000), {
            passive: true,
        });
        document.addEventListener('scroll', updateVisibility, {
            passive: true,
        });
        window.addEventListener('resize', debounce(updateMargin, 300));
        window.addEventListener('resize', debounce(updateVisibility, 300));
    }
}

export function footerVideo() {
    const footer = document.querySelector('footer.site-footer');
    const footerVideo = footer.querySelector('.video-letters video');

    if (footerVideo) {
        const scrollHandler = () => {
            if (
                window.innerHeight + window.pageYOffset + 50 >=
        document.body.offsetHeight
            ) {
                footerVideo.play();
                document.removeEventListener('scroll', scrollHandler);
            }
        };
        document.addEventListener('scroll', scrollHandler, { passive: true });
    }
}
