import gsap from 'gsap';

export class ListingAnimations {
    constructor() {
        this.fadeInListing = {
            containers: document.querySelectorAll('[data-fade-in-listing]') || [],
        };
        this.staggerFlyIn = {
            containers: document.querySelectorAll('[data-stagged-fly-in]') || [],
        };
        this.flyInLeft = {
            containers: document.querySelectorAll('[data-fly-in-left]') || [],
        };

        this.drawLines = document.querySelectorAll('[data-draw-line]') || [];

        this.articleTiles = {
            containers: document.querySelectorAll('.news-slider-block .swiper-wrapper') || [],
        };
        this.init();
    }
    
    init() {
        if (this.fadeInListing.containers.length) {
            this.fadeInListing.containers.forEach((container) => {
                this.fadeInAnimation([...container.children]);
            });
        }

        this.staggerFlyIn.containers.forEach((container) => {
            this.staggedFlyInAnimation(container, [...container.children]);
        });

        this.flyInLeft.containers.forEach((container) => {
            this.flyInLeftAnimation(container, [...container.children]);
        });

        this.drawLines.forEach((line) => {
            this.drawLineAnimation(line);
        });


        //        this.articleTiles.containers.forEach((container) => {
        //            this.articleTileAnimation(container, [...container.children]);
        //        });
    }

    articleTileAnimation(container, tiles) {
        gsap.fromTo(tiles, {
            y: 100,
        }, {
            y: 0,
            stagger: 0.1,
            scrollTrigger: {
                trigger: container,
                start: '50% bottom'
            },
            duration: 1.2,
        });

    }

    drawLineAnimation(line) {
        gsap.fromTo(line, {
            width: 0,
        }, {
            width: '100%',
            duration: 1,
            scrollTrigger: {
                trigger: line,
                start: 'top 95%',
            }
        });
    }
    
    fadeInAnimation(elements) {
        elements.forEach((el, idx) => {
            gsap.set(el, {visibility: 'visible'});
            let realIdx = idx + 1;
            gsap.fromTo(el, {
                yPercent: 40,
            }, {
                yPercent: 0,
                delay: (realIdx % 2  === 0) ? .1 : 0,
                duration: 1,
                scrollTrigger: {
                    trigger: el,
                }
            });
        });
    }

    staggedFlyInAnimation(container, elements) {
        gsap.set(elements, {visibility: 'visible'});
        gsap.fromTo(elements, {
            yPercent: 150,
        }, {
            yPercent: 0,
            scrollTrigger: {
                trigger: container,
            },
            stagger: .05,
            duration: .8,
        });
    }

    flyInLeftAnimation(container, elements) {
        gsap.set(elements, {visibility: 'visible'});
        gsap.fromTo(elements, {
            yPercent: 100,
            opacity: 0,
        }, {
            yPercent: 0,
            opacity: 1,
            scrollTrigger: {
                trigger: container,
            },
            stagger: .1,
            duration: 1,
        });
    }

}