import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import openSimplexNoise from 'https://cdn.skypack.dev/open-simplex-noise';
import { getCssVar } from '../../components/utils/helpers.js';

import { vertexShader, fragmentShader } from './parts/shaders.js';
//import { onScrollAnimation } from './parts/gsap.js';
import {
    findClosestPointToCamera,
    startAnimation,
    resetColor,
    updatePointSize,
} from './parts/helpers.js';

const tabletWidth = parseFloat(getCssVar('--breakpoint-tablet')) + 1;
const mq = window.matchMedia(`(max-width: ${tabletWidth}px)`);
const container = document.querySelector('.three-scene');
if (container) {
    const referenceWidth = 1496;
    const scaleValue = {
        value: 1,
    };
    let rotation = {
        static: -0.001,
        absolute: 0,
        step: 0.1,
    };
    const fractionsScaler = {
        value: 0.3,
    };

    const morphFactor = {
        value: 1,
    };
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    const point = new THREE.Vector3();

    const uniforms = {
    //u_pointsize: { value: 4.3 },
        u_pointsize: { value: updatePointSize(referenceWidth) },
        //        u_windowWidth: {
        //            value: window.innerWidth
        //        },
        uMouse: {
            value: new THREE.Vector3(),
        },
    };

    // Scene
    let scene = new THREE.Scene();
    // Camera
    const canvasSizes = {
        y: 0,
        x: 0,
    };
    if (mq.matches) {
        canvasSizes.x = container.getBoundingClientRect().width;
        canvasSizes.y = container.getBoundingClientRect().width;
    } else {
        canvasSizes.x = window.innerWidth;
        canvasSizes.y = window.innerHeight;
    }
    canvasSizes.x = container.getBoundingClientRect().width;
    canvasSizes.y = container.getBoundingClientRect().width;
    let camera = new THREE.PerspectiveCamera(
        75,
        canvasSizes.x / canvasSizes.y,
        0.1,
        1000,
    );
    camera.position.set(10, 10, 10);
    // Renderer
    let renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(canvasSizes.x, canvasSizes.y);
    renderer.setPixelRatio(window.devicePixelRatio);
    // Append our renderer to the webpage. Basically, this appends the `canvas` to our webpage.
    container.appendChild(renderer.domElement);

    const normalizeCamera = (breakpoint) => {
        const cameraXPos = (9 * window.innerWidth) / referenceWidth;
        //if (breakpoint.matches) {
        camera.position.z = 9;
        camera.position.y = 9.5;
        camera.position.x = 9.5;
        camera.lookAt(-10, -10, -10);
    //} else {
    //    camera.position.z = 12;
    //    camera.position.y = 10;
    //    camera.lookAt(cameraXPos > 9 ? 9 : cameraXPos, 2, 0);
    //}
    };

    window.addEventListener('resize', () => {
        window.requestAnimationFrame(() => {
            if (mq.matches) {
                canvasSizes.x = container.getBoundingClientRect().width;
                canvasSizes.y = container.getBoundingClientRect().width;
            } else {
                canvasSizes.x = window.innerWidth;
                canvasSizes.y = window.innerHeight;
            }
            canvasSizes.x = container.getBoundingClientRect().width;
            canvasSizes.y = container.getBoundingClientRect().width;
            camera.aspect = canvasSizes.x / canvasSizes.y;
            camera.updateProjectionMatrix();
            renderer.setSize(canvasSizes.x, canvasSizes.y);
            uniforms.u_pointsize.value = updatePointSize(referenceWidth);
            normalizeCamera(mq);
            //camera.updateProjectionMatrix();
            //camera.updateMatrixWorld();
        });
    });

    const controls = new OrbitControls(camera, renderer.domElement);

    controls.enabled = false;
    controls.enableDamping = true;
    controls.enableRotate = true;
    controls.enableZoom = true;
    controls.zoomSpeed = 0.5;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 2.0;

    const r = 7;
    const geometry = new THREE.BoxGeometry(1, 1, 1, 18, 18, 18);
    let v = new THREE.Vector3(); // temp vector, for re-use
    const scales = new Float32Array(geometry.attributes.position.count);
    const defaultColor = new THREE.Color('#3E60A4');

    // Create a color attribute with the same length as the vertices
    const colors = new Float32Array(geometry.attributes.position.count * 3);
    for (let i = 0; i < colors.length; i += 3) {
        defaultColor.toArray(colors, i);
    }
    for (let i = 0; i < geometry.attributes.position.count; i++) {
        v.fromBufferAttribute(geometry.attributes.position, i);
        v.normalize().multiplyScalar(r); // or v.setLength(r);
        geometry.attributes.position.setXYZ(i, v.x, v.y, v.z);
        scales[i] = 1;
    }
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));
    geometry.computeVertexNormals();

    geometry.positionData = [];
    let v3 = new THREE.Vector3();
    for (let i = 0; i < geometry.attributes.position.count; i++) {
        v3.fromBufferAttribute(geometry.attributes.position, i);
        geometry.positionData.push(v3.clone());
    }
    const material = new THREE.ShaderMaterial({
        uniforms,
        vertexShader: vertexShader(),
        fragmentShader: fragmentShader(),
    });
    const mesh = new THREE.Points(geometry, material);
    mesh.position.set(0, 0, 0);
    scene.add(mesh);
    let noise = openSimplexNoise.makeNoise4D(Date.now());
    let clock = new THREE.Clock();

    renderer.setAnimationLoop(() => {
        let t = clock.getElapsedTime() / 1;
        geometry.positionData.forEach((p, idx) => {
            var value = noise(p.x * 0.3, p.y * 0.3, p.z * 0.1, t * 0.3);
            v3.copy(p)
                .setZ(p.z * morphFactor.value)
                .setX(p.x * morphFactor.value)
                .setY(p.y * morphFactor.value)
                .multiplyScalar(scaleValue.value)
                .addScaledVector(p, value * fractionsScaler.value);
            geometry.attributes.position.setXYZ(idx, v3.x, v3.y, v3.z);
        });
        geometry.computeVertexNormals();
        uniforms.uMouse.value = point;
        geometry.attributes.position.needsUpdate = true;
        geometry.attributes.scale.needsUpdate = true;
        //camera.applyMatrix4(new THREE.Matrix4().makeRotationY(rotation.static));
        camera.updateMatrixWorld();
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
    });

    mq.addEventListener('change', normalizeCamera);
    normalizeCamera(mq);

    const raycasterEvent = () => {
        container.addEventListener('pointermove', (event) => {
            const rect = renderer.domElement.getBoundingClientRect();
            pointer.x =
        ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
            pointer.y =
        -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

            raycaster.setFromCamera(pointer, camera);

            const intersects = raycaster.intersectObjects([mesh]);

            if (intersects[0]) {
                point.copy(intersects[0].point);
            }
        });
    };

    raycasterEvent();

    window.requestAnimationFrame(async () => {
    //        onScrollAnimation(mq, tabletWidth, container, scaleValue);
        const colorsSet = ['#0abde3', '#52D3D8', '#7BD3EA'];
        let activeColor = 0;
        await new Promise((resolve) => setTimeout(resolve, 150));
        container.classList.add('ready');
        const animationRunner = async () => {
            if (activeColor + 1 > colorsSet.length) activeColor = 0;
            const newColor = new THREE.Color(colorsSet[activeColor]);
            let targetPoint = findClosestPointToCamera(geometry, camera, THREE);
            await new Promise((resolve) =>
                startAnimation(targetPoint, geometry, newColor, resolve),
            );
            //await new Promise((resolve) => pulseSphere(scaleValue, 1.08, resolve));
            await new Promise((resolve) => setTimeout(() => resolve(), 1500));
            resetColor(defaultColor, geometry);
            activeColor++;
            await new Promise((resolve) => setTimeout(() => resolve(), 2000));
            animationRunner();
        };
        setTimeout(animationRunner, 1000);
    });
}
