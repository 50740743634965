import {Swiper} from 'swiper';
import {Autoplay, EffectFade} from 'swiper/modules';


class TextSlider extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.autoplay = Number(this.getAttribute('autoplay'));
        this.progressBar = this.querySelector('.text-slider__meta__progress-bar span');
        this.counterValue = this.querySelector('.text-slider__meta__timer .value');
        this.initSwiper();
    }

    initSwiper() {
        const self = this;
        this.swiper = new Swiper(this, {
            modules: [Autoplay, EffectFade],
            speed: 1000,
            effect: 'fade',
            autoHeight: true,
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: this.autoplay,
                pauseOnMouseEnter: true
            },
            on: {
                autoplayTimeLeft(s, time, progress) {
                    const secondsLeft = Math.ceil(time / 1000);
                    self.progressBar.style.setProperty('--progress', 1 - progress);
                    self.counterValue.textContent = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft;
                }
            }
        });
    }
}

customElements.define('text-slider', TextSlider);