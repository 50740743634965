import tingle from 'tingle.js';

export class SubscribeModalController {
    constructor() {
        this.subscribeModal = document.querySelector('[data-tingle-modal="subscribe-modal"]');
        this.modal = this.createModal();
        return this.modal;
    }
    
    createModal() {
        const closer = this.subscribeModal.querySelector('.modal__closer');
        const modal = new tingle.modal({
            closeMethods: ['overlay', 'escape'],
            closeLabel: 'Close',
            cssClass: ['subscribe-modal'],
        });
        closer.addEventListener('click', () => {
            modal.close();
        });
        modal.setContent(this.subscribeModal);
        return modal;
    }
}