import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function initPotrfolioBackground() {
    const main = document.querySelector('.site-main');
    const header = document.querySelector('.site-header');
    const section = document.querySelector('.portfolio-section');
    if (!section) return;
    const greyTl = gsap.timeline({ paused: true });
    greyTl.fromTo(
        [main, header],
        {
            //backgroundColor: 'rgba(255,255,255, 1)',
        },
        {
            //backgroundColor: 'rgba(202, 219, 224, 1)',
            //duration: 0.5,
        },
    );

    ScrollTrigger.create({
        trigger: section,
        start: 'top center',
        end: 'bottom center',
        onEnter: () => {
            greyTl.play();
        },
        onLeave: () => {
            greyTl.reverse();
        },
        onEnterBack: () => {
            greyTl.play();
        },
        onLeaveBack: () => {
            greyTl.reverse();
        },
    });
}
