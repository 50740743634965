import gsap from 'gsap';
import * as THREE from 'three';

let busyDots = [];

function pulsePoint(pointData, geometry) {
    const topTarget = 2;
    const bottomTarget = 1;
    const currentValue = {
        value: geometry.attributes.scale.array[pointData.index],
    };
    const tl = gsap.timeline();
    tl.to(currentValue, {
        value: topTarget,
        duration: 0.4,
        onUpdate: function () {
            geometry.attributes.scale.array[pointData.index] = currentValue.value;
        },
    });
    tl.to(currentValue, {
        value: bottomTarget,
        duration: 0.2,
        onUpdate: function () {
            geometry.attributes.scale.array[pointData.index] = currentValue.value;
        },
    });
}

export async function startAnimation(pointData, geometry, newColor, resolve) {
    const targetScale = 3;
    const currentValue = {
        value: geometry.attributes.scale.array[pointData.index],
    };

    const scaleUp = async (resolve) => {
        gsap.to(currentValue, {
            value: targetScale,
            duration: 1,
            onUpdate: function () {
                geometry.attributes.scale.array[pointData.index] = currentValue.value;
            },
            onComplete: () => resolve(),
        });
    };
    const scaleDown = () => {
        gsap.to(currentValue, {
            value: 1,
            duration: 1,
            onUpdate: function () {
                geometry.attributes.scale.array[pointData.index] = currentValue.value;
            },
        });
    };
    await Promise.all([
        new Promise((resolve) => scaleUp(resolve)),
        new Promise((resolve) =>
            changePointColor(pointData.index, newColor, geometry, resolve, 10, 0.15),
        ),
    ]);
    busyDots.push(pointData.index);
    setTimeout(() => {
        scaleDown();
    }, 400);
    await new Promise((resolve) =>
        startCovering(pointData, newColor, geometry, resolve),
    );
    setTimeout(() => {
        busyDots = [];
        resolve();
    }, 500);
}

export async function changePointColor(
    selectedPointIndex,
    newColor,
    geometry,
    resolve = () => true,
    repeat = 0,
    duration = 0.1,
) {
    //    geometry.attributes.color.setXYZ(
    //        selectedPointIndex,
    //        newColor.r,
    //        newColor.g,
    //        newColor.b
    //    );
    //    geometry.attributes.color.needsUpdate = true;
    //    resolve();
    const tl = gsap.timeline({ yoyo: true, repeat, onComplete: () => resolve() });
    const currentColor = getColorOfSelectedPoint(selectedPointIndex, geometry);
    tl.to(currentColor, {
        r: newColor.r,
        g: newColor.g,
        b: newColor.b,
        duration,
        onUpdate: function () {
            // Update the color attribute during the animation
            geometry.attributes.color.setXYZ(
                selectedPointIndex,
                currentColor.r,
                currentColor.g,
                currentColor.b,
            );
            geometry.attributes.color.needsUpdate = true;
        },
    });
}

export function findClosestPointToCamera(geometry, camera) {
    // Get the positions from the geometry
    let positions = geometry.attributes.position.array;

    // Camera position
    let cameraPosition = new THREE.Vector3();
    camera.getWorldPosition(cameraPosition);

    // Variables to store the closest point and its distance
    let closestPoint;
    let closestDistance = Infinity;
    let closestIndex = -1;

    // Iterate through the positions and find the closest point
    for (let i = 0; i < positions.length; i += 3) {
        let point = new THREE.Vector3(
            positions[i],
            positions[i + 1],
            positions[i + 2],
        );
        let distance = cameraPosition.distanceTo(point);

        if (distance < closestDistance) {
            closestDistance = distance;
            closestPoint = point.clone();
            closestIndex = i / 3;
        }
    }

    return { point: closestPoint, index: closestIndex };
}

export function getRandomPoint(geometry) {
    // Get the total number of vertices
    let totalVertices = geometry.attributes.position.count;

    // Generate a random index
    let randomIndex = Math.floor(Math.random() * totalVertices);

    // Get the position of the random point
    let randomPoint = new THREE.Vector3(
        geometry.attributes.position.getX(randomIndex),
        geometry.attributes.position.getY(randomIndex),
        geometry.attributes.position.getZ(randomIndex),
    );

    return randomPoint;
}

export function findNearbyPoints(selectedPoint, radius, geometry) {
    const distances = [];

    // Get the positions from the geometry
    let positions = geometry.attributes.position.array;

    for (let i = 0; i < positions.length; i += 3) {
        let point = new THREE.Vector3(
            positions[i],
            positions[i + 1],
            positions[i + 2],
        );
        let distance = selectedPoint.point.distanceTo(point);
        if (distance < radius) {
            distances.push({ point, index: i / 3, distance });
        }
    }

    function sortByDistance(a, b) {
        return a.distance - b.distance;
    }

    distances.sort(sortByDistance);
    return distances.slice(0, 8);
}

export function getColorOfSelectedPoint(selectedPointIndex, geometry) {
    // Get the colors from the geometry
    let colors = geometry.attributes.color.array;

    // Extract RGB values for the selected point
    let color = new THREE.Color(
        colors[selectedPointIndex * 3],
        colors[selectedPointIndex * 3 + 1],
        colors[selectedPointIndex * 3 + 2],
    );

    return color;
}

export async function startCovering(pointData, newColor, geometry, resolve) {
    if (geometry.attributes.position.count === busyDots.length) {
        resolve();
        return;
    }
    const surrounding = findNearbyPoints(pointData, 3, geometry);
    const filtered = surrounding.filter((obj) => !busyDots.includes(obj.index));
    //console.log(filtered.length, 'covering');
    if (!filtered.length) return;
    busyDots = [...busyDots, ...filtered.map((obj) => obj.index)];
    const forLoop = async (_) => {
        for (let idx = 0; idx < filtered.length; idx++) {
            pulsePoint(filtered[idx], geometry);
            await new Promise((resolve) =>
                changePointColor(filtered[idx].index, newColor, geometry, resolve, 0),
            );
        }
    };
    await forLoop();
    if (filtered.length) {
        setTimeout(() => {
            filtered.forEach((obj) => {
                startCovering(obj, newColor, geometry, resolve);
            });
        }, 100);
    }
}

export function resetColor(color, geometry) {
    const pointsWithIndices = getAllPoints(geometry);
    pointsWithIndices.forEach((pointData) => {
        changePointColor(pointData.index, color, geometry, () => true, 0, 1.5);
    });
}

function getAllPoints(geometry) {
    const pointsWithIndices = [];
    const positions = geometry.attributes.position.array;

    for (let i = 0; i < positions.length; i += 3) {
        const index = i / 3;
        const point = new THREE.Vector3(
            positions[i],
            positions[i + 1],
            positions[i + 2],
        );
        pointsWithIndices.push({ point, index });
    }
    return pointsWithIndices;
}

function getPointByIndex(index, geometry) {
    // Ensure that the index is within bounds
    if (index >= 0 && index < geometry.attributes.position.count) {
    // Get the position of the point using the index
        const position = new THREE.Vector3();
        const positions = geometry.attributes.position.array;

        // Calculate the offset for the given index
        const offset = index * geometry.attributes.position.itemSize;

        // Extract X, Y, and Z coordinates from the array
        position.x = positions[offset];
        position.y = positions[offset + 1];
        position.z = positions[offset + 2];
        return position;
    } else {
        console.error('Index out of bounds');
        return null;
    }
}

export function pulseSphere(scaler, target, resolve) {
    const tl = gsap.timeline();
    tl.to(scaler, {
        value: target,
        duration: 1.25,
    });
    tl.to(
        scaler,
        {
            value: 1,
            duration: 0.75,
            onStart: () => resolve(),
        },
        '+=.5',
    );
}

export function updatePointSize(referenceWidth) {
    const ratio = window.innerWidth / referenceWidth;
    let pointSize = window.devicePixelRatio * 2.15 * ratio;
    if (pointSize < window.devicePixelRatio) {
        pointSize = window.devicePixelRatio;
    }
    if (pointSize > 6) {
        pointSize = 6;
    }
    return pointSize;
}
