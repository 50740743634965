import Swiper from 'swiper';
import { Navigation, Mousewheel } from 'swiper/modules';

class NewsSlider extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.initSwiper();
    }

    initSwiper() {
        this.swiper = new Swiper(this, {
            modules: [Navigation, Mousewheel],
            slidesPerView: 'auto',
            spaceBetween: 20,
            speed: 1000,
            navigation: {
                prevEl: this.querySelector('.swiper-nav-item--prev'),
                nextEl: this.querySelector('.swiper-nav-item--next'),
            },
            mousewheel: {
                forceToAxis: true,
            },
            breakpoints: {
                1024: {
                    spaceBetween: 32,
                },
            },
        });
    }
}

customElements.define('news-slider', NewsSlider);
