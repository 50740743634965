export class Dropdown {
    constructor(dropdown, isCloseOnSelect = false) {
        this.isCloseOnSelect = isCloseOnSelect;
        this.dropdownParent = dropdown;
        this.dropdownTrigger = dropdown.querySelector('.filter-item__button');
        this.dropdownOptions = dropdown.querySelectorAll('.filter-item__option button');
        this.isShown = false;
        this.attachListeners();
        this.boundListener = this.clickOutsideHandler.bind(this);
    }
    
    attachListeners() {
        this.dropdownTrigger.addEventListener('click', this.toggleDropdown.bind(this));
        if (this.isCloseOnSelect) {
            this.dropdownOptions.forEach((option) => option.addEventListener('click', (e) => {
                e.stopPropagation();
                this.hideDropdown();
            }));
        }
    }
    
    toggleDropdown() {
        this.isShown ? this.hideDropdown() : this.showDropdown();
    }
    
    clickOutsideHandler(e) {
        const dropdownParent = e.target.closest('.' + this.dropdownParent.className);
        if (e.target === this.dropdownParent) this.hideDropdown();
        if (!e.target || !dropdownParent || dropdownParent !== this.dropdownParent || e.target === this.dropdownTrigger) {
            this.hideDropdown();
        }
    }
    
    updateFiltersCounter(activeFilters) {
        const counter = this.dropdownTrigger.querySelector('.filter-item__button__quantity');
        counter.textContent = activeFilters ? `(${activeFilters})` : '';
    }
    
    showDropdown() {
        this.isShown = true;
        this.dropdownTrigger.classList.add('active');
        setTimeout(() => {
            document.addEventListener('click', this.boundListener);
        }, 0);
        
    }
    
    hideDropdown() {
        this.dropdownTrigger.blur();
        this.dropdownOptions.forEach((option) => option.blur());
        this.isShown = false;
        this.dropdownTrigger.classList.remove('active');
        document.removeEventListener('click', this.boundListener);
    }
}