import {gsap} from 'gsap';
import {onCloseModal, onOpenModal} from '../../components/utils/helpers.js';
export class MobileMenu {
    constructor() {
        this.header = document.querySelector('.site-header');
        this.burger = this.header.querySelector('.burger');
        this.mobileMenu = this.header.querySelector('.mobile-menu');
        this.menuItems = this.mobileMenu.querySelectorAll('.mobile-menu__menu-nav li');
        this.socialItems = this.mobileMenu.querySelectorAll('.mobile-menu__socials-nav li');
        this.isMobileMenuOpen = false;
        this.tl = this.createAnimation();
        this.attachListeners();
    }
    
    createAnimation() {
        const tl = gsap.timeline({paused:true, onStart: () => onOpenModal(), onReverseComplete: () => onCloseModal()});
        tl.fromTo(this.mobileMenu, {autoAlpha: 0}, { autoAlpha: 1});
        tl.fromTo(this.menuItems, {y: 40, opacity: 0}, {y:0, opacity: 1, stagger: .1}, '-=.3');
        tl.fromTo(this.socialItems, {y: 40, opacity: 0}, {y:0, opacity: 1, stagger: .1}, '-=.5');
        return tl;
    }
    attachListeners() {
        this.burger.addEventListener('click', this.toggleMenu.bind(this));
    }
    
    toggleMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
        this.header.classList.toggle('mobile-menu-open');
        this.isMobileMenuOpen ? this.openMenu() : this.closeMenu();
    }
    
    openMenu() {
        this.tl.play().timeScale(1);
    }
    
    closeMenu() {
        this.tl.reverse().timeScale(-2);
    }
}